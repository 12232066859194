/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { useTheme, withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PropTypes from 'prop-types';

import AnalyticsIcon from '@parkly/shared/components/atoms/icons/AnalyticsIcon';
import AnalyticsIconActive from '@parkly/shared/components/atoms/icons/AnalyticsIconActive';
import BalanceActiveIcon from '@parkly/shared/components/atoms/icons/BalanceActiveIcon';
import BalanceInactiveIcon from '@parkly/shared/components/atoms/icons/BalanceInactiveIcon';
import DocumentsActiveIcon from '@parkly/shared/components/atoms/icons/DocumentsActiveIcon';
import DocumentsInactiveIcon from '@parkly/shared/components/atoms/icons/DocumentsInactiveIcon';
import GuestPassesIcon from '@parkly/shared/components/atoms/icons/GuestPassesIcon';
import GuestPassesIconActive from '@parkly/shared/components/atoms/icons/GuestPassesIconActive';
import HelpActiveIcon from '@parkly/shared/components/atoms/icons/HelpActiveIcon';
import HelpInactiveIcon from '@parkly/shared/components/atoms/icons/HelpInactiveIcon';
import HomeActiveIcon from '@parkly/shared/components/atoms/icons/HomeActiveIcon';
import HomeInactiveIcon from '@parkly/shared/components/atoms/icons/HomeInactiveIcon';
import LogOutIcon from '@parkly/shared/components/atoms/icons/LogOutIcon';
import OperatorsIcons from '@parkly/shared/components/atoms/icons/OperatorsIcons';
import OperatorsIconsActive from '@parkly/shared/components/atoms/icons/OperatorsIconsActive';
import P360LogoIcon from '@parkly/shared/components/atoms/icons/P360LogoIcon';
import SettingsActiveIcon from '@parkly/shared/components/atoms/icons/SettingsActiveIcon';
import SettingsInactiveIcon from '@parkly/shared/components/atoms/icons/SettingsInactiveIcon';
import NavigationLink from '@parkly/shared/components/atoms/NavigationLink';
import { DEFAULT_PATH_PAGES } from '@parkly/shared/config/constants';

import CurrentFacilitySelect from 'components/molecules/CurrentFacilitySelect';
import SettingsNavigationLinks from 'components/organisms/SettingsNavigationLinks';
import {
  MODULES_KEYS, PATH_PAGES, RIGHT_KEYS, ROLES,
} from 'config/constants';
import {
  useCheckFacilityRights, useCommonRights, useHasModule, useIsOperatorRole,
} from 'helpers/hooks';

import { useStyles } from './styles';

export const CustomListItemMenu = withStyles({
  root: {
    height: 56,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: (props) => (props.isCollapsed ? 'center' : 'flex-start'),
    gap: 8,
    paddingLeft: (props) => (props.isCollapsed ? 0 : 24),
    alignItems: 'center',
    borderRadius: 12,
    '& .MuiListItemText-root .MuiTypography-root.MuiListItemText-primary': {
      fontSize: 14,
    },
    padding: 0,
    textAlign: 'center',
  },
})(ListItem);

/* Main component */

const propTypes = {
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  drawerWidth: PropTypes.number,
};

function LeftPageMenu({
  open = false,
  variant = 'permanent',
  drawerWidth = 300,
  currentFacilityId,
  userRights,
  registrationStatus,
  onToggleCollapse = () => {},
  isCollapsed,
  isCollapsedEnabled = false,
  ...props
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles({ drawerWidth });

  const isSettingsMatch = useRouteMatch(PATH_PAGES.settings);

  const isAdmin = useIsOperatorRole(ROLES.admin);
  const isDealer = useIsOperatorRole(ROLES.dealer);
  const hasOperatorUpdateRight = isAdmin || isDealer;
  const hasAccountUpdateRight = useCommonRights(RIGHT_KEYS.accountUpdate);
  const hasAnalyticsShowRight = useCommonRights(RIGHT_KEYS.analyticsV2Show);
  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, currentFacilityId);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, currentFacilityId);
  const hasPassShowRight = useCheckFacilityRights(RIGHT_KEYS.passShow, currentFacilityId);
  const hasTicketShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, currentFacilityId);
  const hasBlackListShowRight = useCommonRights(RIGHT_KEYS.blackListShow);
  const hasFacilityShowRight = useCheckFacilityRights(RIGHT_KEYS.facilityShow, currentFacilityId);
  const hasPlacesShowRight = useCheckFacilityRights(RIGHT_KEYS.placesShow, currentFacilityId);
  const hasAutomationShowRight = useCheckFacilityRights(RIGHT_KEYS.automationShow, currentFacilityId);
  const hasTariffShowRight = useCheckFacilityRights(RIGHT_KEYS.tariffShow, currentFacilityId);
  const hasPassTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.passTypeShow, currentFacilityId);
  const hasSiteShowRight = useCheckFacilityRights(RIGHT_KEYS.siteShow, currentFacilityId);

  const hasPaymentModule = useHasModule(currentFacilityId, MODULES_KEYS.payments);

  const hasSettingsShowRight = hasFacilityShowRight
    || hasPlacesShowRight
    || hasAutomationShowRight
    || hasTariffShowRight
    || hasPassTypeShowRight
    || hasSiteShowRight;

  return (
    <Drawer
      variant={variant}
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
      {...props}
    >
      <div className={classes.container}>
        <div className={classes.toolbar} />
        <Divider />
        <List className={classes.drawerList}>
          <NavigationLink to={PATH_PAGES.home}>
            <CustomListItemMenu isCollapsed={isCollapsed} button key="Logo">
              <P360LogoIcon className={classes.logo} />
            </CustomListItemMenu>
          </NavigationLink>
          <CurrentFacilitySelect isCollapsed={isCollapsed} />
          <NavigationLink className={classes.navigationLink} to={PATH_PAGES.home}>
            {({ isActive }) => (
              <CustomListItemMenu
                isCollapsed={isCollapsed}
                className={isActive ? classes.activeCustomListItemMenu : ''}
                button
              >
                {isActive ? <HomeActiveIcon size={24} /> : <HomeInactiveIcon size={24} />}
                {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.home')} />}
              </CustomListItemMenu>
            )}
          </NavigationLink>
          {(hasCustomerShowRight || hasTicketShowRight || hasPassShowRight || hasBlackListShowRight) && (
            <NavigationLink
              replace
              className={classes.navigationLink}
              to={PATH_PAGES.customers.replace(':facilityId', currentFacilityId)}
              matchTo={[PATH_PAGES.customers, PATH_PAGES.passes, PATH_PAGES.tickets, PATH_PAGES.blackList]}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  isCollapsed={isCollapsed}
                  className={isActive ? classes.activeCustomListItemMenu : ''}
                  button
                >
                  {isActive ? <GuestPassesIconActive size={24} /> : <GuestPassesIcon size={24} />}
                  {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.customers')} />}
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasSessionShowRight && (
            <NavigationLink
              replace
              className={classes.navigationLink}
              to={PATH_PAGES.sessions.replace(':facilityId', currentFacilityId)}
              matchTo={[PATH_PAGES.sessions, PATH_PAGES.payments, PATH_PAGES.gatewayEvents]}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  isCollapsed={isCollapsed}
                  className={isActive ? classes.activeCustomListItemMenu : ''}
                  button
                >
                  {isActive ? <DocumentsActiveIcon size={24} /> : <DocumentsInactiveIcon size={24} />}
                  {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.sessions')} />}
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasSettingsShowRight && (
            <NavigationLink className={classes.navigationLink} to={PATH_PAGES.settings}>
              {({ isActive }) => (
                <CustomListItemMenu
                  isCollapsed={isCollapsed}
                  className={isActive ? classes.activeCustomListItemMenu : ''}
                  button
                >
                  {/* { isActive ? <SitesIconActive size={24} /> : <SitesIcon size={24} /> } */}
                  {isActive ? <SettingsActiveIcon size={24} /> : <SettingsInactiveIcon size={24} />}
                  {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.settings')} />}
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {!!isSettingsMatch && !isCollapsed && (
            <Hidden mdUp implementation="css">
              <SettingsNavigationLinks className={classes.settingsList} facilityId={currentFacilityId} registrationStatus={registrationStatus} />
            </Hidden>
          )}
          {hasOperatorUpdateRight && (
            <NavigationLink className={classes.navigationLink} to={PATH_PAGES.operators}>
              {({ isActive }) => (
                <CustomListItemMenu
                  isCollapsed={isCollapsed}
                  className={isActive ? classes.activeCustomListItemMenu : ''}
                  button
                >
                  {isActive ? <OperatorsIconsActive size={24} /> : <OperatorsIcons size={24} />}
                  {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.operators')} />}
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasAnalyticsShowRight && (
            <NavigationLink
              className={classes.navigationLink}
              to={PATH_PAGES.analyticsv2}
              matchTo={[PATH_PAGES.analyticsv2]}
            >
              {({ isActive }) => (
                <CustomListItemMenu
                  isCollapsed={isCollapsed}
                  className={isActive ? classes.activeCustomListItemMenu : ''}
                  button
                >
                  {isActive ? <AnalyticsIconActive size={24} /> : <AnalyticsIcon size={24} />}
                  {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.analytics')} />}
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          {hasAccountUpdateRight && hasPaymentModule && (
            <NavigationLink className={classes.navigationLink} to={PATH_PAGES.account}>
              {({ isActive }) => (
                <CustomListItemMenu
                  isCollapsed={isCollapsed}
                  className={isActive ? classes.activeCustomListItemMenu : ''}
                  button
                >
                  {isActive ? <BalanceActiveIcon size={24} /> : <BalanceInactiveIcon size={24} />}
                  {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.account')} />}
                </CustomListItemMenu>
              )}
            </NavigationLink>
          )}
          <NavigationLink className={classes.navigationLink} to={PATH_PAGES.help}>
            {({ isActive }) => (
              <CustomListItemMenu
                isCollapsed={isCollapsed}
                className={isActive ? classes.activeCustomListItemMenu : ''}
                button
              >
                {isActive ? <HelpActiveIcon size={24} /> : <HelpInactiveIcon size={24} />}
                {!isCollapsed && <ListItemText className={classes.text} primary={t('navigation.help')} />}
              </CustomListItemMenu>
            )}
          </NavigationLink>
          {isCollapsedEnabled && (
            <div className={classes.logoutBtn}>
              <CustomListItemMenu isCollapsed={isCollapsed} button onClick={onToggleCollapse}>
                {isCollapsed ? (
                  <ChevronRightIcon color="secondary" fontSize="medium" />
                ) : (
                  <ChevronLeftIcon color="secondary" fontSize="medium" />
                )}
                {!isCollapsed && <ListItemText className={classes.text} primary="Свернуть панель" />}
              </CustomListItemMenu>
            </div>
          )}
          <NavigationLink
            className={[classes.navigationLink, !isCollapsedEnabled ? classes.logoutBtn : ''].join(' ')}
            to={DEFAULT_PATH_PAGES.logout}
          >
            <CustomListItemMenu isCollapsed={isCollapsed} button>
              <LogOutIcon size={24} />
            </CustomListItemMenu>
          </NavigationLink>
        </List>
      </div>
    </Drawer>
  );
}

LeftPageMenu.propTypes = propTypes;

export default LeftPageMenu;
