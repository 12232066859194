import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import {
  clearContractorCacheAction,
  getContractorFLAction,
  getContractorULAction,
} from '@parkly/shared/actions/contractor';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import {
  createMailLink, useDidUpdateEffect, useFormFields, validateINN,
} from '@parkly/shared/helpers';

import { getCompanyAction, setCompanyInfoAction } from 'actions/company';
import { DEFAULT_RECEIPT_PHONE } from 'config/constants';

import { useStyles } from './styles';

function CompanySettingsPage({
  getContractorUlReg,
  getContractorFlReg,
  clearContractorCache,
  setCompanyInfoReq,

  isSetCompanyLoading,
  contractorData,

  getCompanyReq,
  companyState,

  registrationStatusData,
}) {
  const { t } = useTranslation();
  const styles = useStyles();
  const history = useHistory();

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: '',
      inn: '',
      kpp: '',
      email: '',
      postcode: '',
      region: '',
      street: '',
      house: '',
      withoutPostalAddress: true,
      postalPostcode: '',
      postalRegion: '',
      postalStreet: '',
      postalHouse: '',
      accountNumber: '',
      corrNumber: '',
      bankCode: '',
      bankName: '',
      receiptPhone: DEFAULT_RECEIPT_PHONE,
    },
  });

  useEffect(
    () => {
      const {
        loading,
        data: companyData,
      } = companyState || {};

      if (loading) {
        return;
      }

      const isCompanyLoaded = !!companyData;

      if (isCompanyLoaded) {
        updateState({
          name: companyData.name || '',
          inn: companyData.inn || '',
          kpp: companyData.kpp || '',
          email: companyData.email || '',
          postcode: companyData.postcode || '',
          region: companyData.region || '',
          street: companyData.street || '',
          house: companyData.house || '',
          withoutPostalAddress: !(companyData.postalPostcode || companyData.postalRegion || companyData.postalStreet || companyData.postalHouse),
          postalPostcode: companyData.postalPostcode || '',
          postalRegion: companyData.postalRegion || '',
          postalStreet: companyData.postalStreet || '',
          postalHouse: companyData.postalHouse || '',
          accountNumber: companyData.accountNumber || '',
          corrNumber: companyData.corrNumber || '',
          bankCode: companyData.bankCode || '',
          bankName: companyData.bankName || '',
          receiptPhone: companyData.receiptPhone || DEFAULT_RECEIPT_PHONE,
        });
        return;
      }

      getCompanyReq();
    },
    [companyState, getCompanyReq, updateState],
  );

  const {
    data: company,
  } = companyState || {};

  const {
    isCompleted,
  } = company || {};

  const { isRegistrationCompleted } = registrationStatusData || {};

  useDidUpdateEffect(() => {
    if (fields.postcode || fields.region || fields.street || fields.house) {
      return;
    }

    if (contractorData) {
      updateState({
        postcode: contractorData.address.postcode,
        region: contractorData.address.region,
        street: contractorData.address.street,
        house: contractorData.address.house,
        kpp: contractorData.kpp,
      });
      setErrors({
        postcode: false,
        region: false,
        street: false,
        house: false,
        kpp: false,
      });
    }
  }, [contractorData]);

  useDidUpdateEffect(() => {
    if (isCompleted) {
      return;
    }

    const inn = fields.inn.replace(/\s/g, '');

    if ([10, 12].includes(inn.length)) {
      const isValidInn = validateINN(inn);

      setErrors({
        inn: !isValidInn,
      });

      if (isValidInn) {
        clearContractorCache();
        if (inn.length === 10) {
          getContractorUlReg(inn);
        }
        if (inn.length === 12) {
          getContractorFlReg(inn);
        }
      }
    }
  }, [fields.inn]);

  const onSubmit = (e) => {
    e.preventDefault();
    e.nativeEvent.preventDefault();

    const isInnValid = validateINN(fields.inn);
    const isKppValid = fields.kpp.length > 0;
    const isEmailValid = fields.email.length > 0;
    const isNameValid = fields.name.length > 0;
    const isPostcodeValid = fields.postcode.length > 0;
    const isRegionValid = fields.region.length > 0;
    const isStreetValid = fields.street.length > 0;
    const isHouseValid = fields.house.length > 0;
    const isAccountNumberValid = fields.accountNumber.length > 0;
    const isCorrNumberValid = fields.corrNumber.length > 0;
    const isBankCodeValid = fields.bankCode.length > 0;
    const isBankNameValid = fields.bankName.length > 0;
    const isReceiptPhoneValid = fields.receiptPhone.length > 0;

    if (
      !isInnValid || !isKppValid || !isNameValid
      || !isPostcodeValid || !isRegionValid
      || !isStreetValid || !isHouseValid
      || !isAccountNumberValid || !isCorrNumberValid
      || !isBankCodeValid || !isBankNameValid || !isEmailValid
      || !isReceiptPhoneValid
    ) {
      setErrors({
        inn: !isInnValid,
        name: !isNameValid,
        email: !isEmailValid,
        postcode: !isPostcodeValid,
        region: !isRegionValid,
        street: !isStreetValid,
        house: !isHouseValid,
        kpp: !isKppValid,
        accountNumber: !isAccountNumberValid,
        corrNumber: !isCorrNumberValid,
        bankCode: !isBankCodeValid,
        bankName: !isBankNameValid,
        receiptPhone: !isReceiptPhoneValid,
      });
      return;
    }

    setCompanyInfoReq({
      id: company.id,
      inn: fields.inn,
      kpp: fields.kpp,
      email: fields.email,
      name: fields.name,
      postcode: fields.postcode,
      region: fields.region,
      street: fields.street,
      house: fields.house,
      postalPostcode: fields.postalPostcode,
      postalRegion: fields.postalRegion,
      postalStreet: fields.postalStreet,
      postalHouse: fields.postalHouse,
      accountNumber: fields.accountNumber,
      corrNumber: fields.corrNumber,
      bankCode: fields.bankCode,
      bankName: fields.bankName,
      receiptPhone: fields.receiptPhone,
    }, history, !isRegistrationCompleted);
  };

  const onWriteToUsClick = useCallback(() => {
    const email = process.env.REACT_APP_SUPPORT_EMAIL;
    const subject = `Внести изменения в информацию о площадке ${fields.name} (${company.id})`;
    const message = '';

    const mailLink = createMailLink({
      email,
      subject,
      message,
    });

    document.location.href = mailLink;
  }, [fields, company]);

  return (
    <div className={styles.container}>
      <HeaderWithBackBtn
        title={t('company.companySetting')}
        isBackBtn={false}
      />
      <form
        className={styles.form}
        autoComplete="off"
        onSubmit={onSubmit}
        noValidate
      >
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12} lg={9}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.name}
                  name="name"
                  label={t('addCompany.name')}
                  value={fields.name}
                  autoComplete="off"
                  required
                  disabled={isRegistrationCompleted}
                  inputProps={{
                    maxLength: 250,
                  }}
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.inn}
                  name="inn"
                  required
                  disabled={isRegistrationCompleted}
                  label={t('addCompany.inn')}
                  value={fields.inn}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.kpp}
                  name="kpp"
                  required
                  disabled={isRegistrationCompleted}
                  label={t('addCompany.kpp')}
                  value={fields.kpp}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.email}
                  name="email"
                  required
                  disabled={isRegistrationCompleted}
                  label={t('addCompany.email')}
                  value={fields.email}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Divider className={styles.divider} />
            <Typography className={styles.subTitle}>
              {t('addCompany.legalAddress')}
            </Typography>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.postcode}
                  name="postcode"
                  required
                  disabled={isRegistrationCompleted}
                  label={t('addCompany.postcode')}
                  value={fields.postcode}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.region}
                  name="region"
                  required
                  disabled={isRegistrationCompleted}
                  label={t('addCompany.region')}
                  value={fields.region}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <Grid container item spacing={2}>
              <Grid item xs={12} sm={8}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.street}
                  name="street"
                  required
                  disabled={isRegistrationCompleted}
                  label={t('addCompany.street')}
                  value={fields.street}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CustomTextInput
                  className={styles.inputItem}
                  error={errors.house}
                  name="house"
                  required
                  disabled={isRegistrationCompleted}
                  label={t('addCompany.house')}
                  value={fields.house}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            <FormControlLabel
              className={styles.checkbox}
              control={(
                <Checkbox
                  disabled={isRegistrationCompleted}
                  checked={fields.withoutPostalAddress}
                  name="withoutPostalAddress"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label={t('addCompany.withoutPostalAddress')}
            />
            <Divider className={styles.divider} />

            {!fields.withoutPostalAddress && (
              <>
                <Typography className={styles.subTitle}>
                  Почтовый адрес
                </Typography>
                <Grid container item spacing={2}>
                  <Grid item xs={6}>
                    <CustomTextInput
                      className={styles.inputItem}
                      required
                      disabled={isRegistrationCompleted}
                      error={errors.postalPostcode}
                      name="postalPostcode"
                      label={t('addCompany.postcode')}
                      value={fields.postalPostcode}
                      autoComplete="off"
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextInput
                      className={styles.inputItem}
                      required
                      disabled={isRegistrationCompleted}
                      error={errors.postalRegion}
                      name="postalRegion"
                      label={t('addCompany.region')}
                      value={fields.postalRegion}
                      autoComplete="off"
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item xs={8}>
                    <CustomTextInput
                      className={styles.inputItem}
                      required
                      disabled={isRegistrationCompleted}
                      error={errors.postalStreet}
                      name="postalStreet"
                      label={t('addCompany.street')}
                      value={fields.postalStreet}
                      autoComplete="off"
                      onChange={onChange}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CustomTextInput
                      className={styles.inputItem}
                      required
                      disabled={isRegistrationCompleted}
                      error={errors.postalHouse}
                      name="postalHouse"
                      label={t('addCompany.house')}
                      value={fields.postalHouse}
                      autoComplete="off"
                      onChange={onChange}
                    />
                  </Grid>
                </Grid>
                <Divider className={styles.divider} />
              </>
            )}
            <Typography className={styles.subTitle}>
              {t('addCompany.accounts')}
            </Typography>
            <Grid container item direction="column" spacing={2}>
              <Grid item xs={12}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  disabled={isRegistrationCompleted}
                  error={errors.accountNumber}
                  name="accountNumber"
                  label={t('addCompany.accountNumber')}
                  value={fields.accountNumber}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  disabled={isRegistrationCompleted}
                  error={errors.corrNumber}
                  name="corrNumber"
                  label={t('addCompany.corrNumber')}
                  value={fields.corrNumber}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  disabled={isRegistrationCompleted}
                  error={errors.bankCode}
                  name="bankCode"
                  label={t('addCompany.bankCode')}
                  value={fields.bankCode}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  disabled={isRegistrationCompleted}
                  error={errors.bankName}
                  name="bankName"
                  label={t('addCompany.bankName')}
                  value={fields.bankName}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CustomTextInput
                  className={styles.inputItem}
                  required
                  disabled={isRegistrationCompleted}
                  error={errors.receiptPhone}
                  name="receiptPhone"
                  label={t('addCompany.receiptPhone')}
                  value={fields.receiptPhone}
                  autoComplete="off"
                  onChange={onChange}
                />
              </Grid>
            </Grid>
            {!isRegistrationCompleted && (
              <Grid item xs={6}>
                <CustomBtn
                  className={styles.btnSubmit}
                  btnType="primaryBlue"
                  disabled={isSetCompanyLoading}
                  type="submit"
                >
                  {t('addCompany.save')}
                </CustomBtn>
              </Grid>
            )}
            {isRegistrationCompleted && (
              <Grid item container xs={12}>
                <Box className={styles.boxWriteToUS}>
                  <Typography>
                    {t('company.writeToUs')}
                  </Typography>
                  <CustomBtn
                    btnType="secondary"
                    className={styles.writeToUsBtn}
                    onClick={onWriteToUsClick}
                  >
                    {t('company.writeToUsBtn')}
                  </CustomBtn>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}

function mapStateToProps(state) {
  const { companies, contractor } = state || {};
  const { company, setCompany } = companies || {};
  const { registrationStatus } = companies || {};
  const { contractor: contractorState } = contractor || {};
  const { data: contractorData } = contractorState || {};
  const { loading } = setCompany;

  const { data: registrationStatusData } = registrationStatus || {};

  return {
    registrationStatusData,
    companyState: company,
    contractorData,
    isSetCompanyLoading: loading,
  };
}

const ConnectedCompanySettingsPage = connect(
  mapStateToProps,
  {
    getCompanyReq: getCompanyAction,
    getContractorUlReg: getContractorULAction,
    getContractorFlReg: getContractorFLAction,
    clearContractorCache: clearContractorCacheAction,
    setCompanyInfoReq: setCompanyInfoAction,
  },
)(CompanySettingsPage);

export default ConnectedCompanySettingsPage;
