import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CircularProgress, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';

import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomTextInput from '@parkly/shared/components/atoms/CustomTextInput';
import ItemSelectors from '@parkly/shared/components/molecules/ItemSelectors';
import CustomModalContentWrapper from '@parkly/shared/components/templates/CustomModalContentWrapper';
import { useFormFields } from '@parkly/shared/helpers';

import { setControllerAction } from 'actions/controllers';
import { setController, testNewController } from 'api/controllers';
import { useOpenVpnClients } from 'api/query/openvpn';
import { CONTROLLER_TYPES } from 'config/constants';

import { useStyles } from './styles';

function getCurrentController({
  id,
  facilityId,
  allControllersState,
}) {
  const {
    list: controllersList,
  } = allControllersState || {};

  const currentStrParam = JSON.stringify({
    facilityId,
  });

  const currentControllers = controllersList.find((item) => {
    const { strParam } = item || {};
    const isCurrent = strParam === currentStrParam;

    return isCurrent;
  });

  const {
    data: controllersData,
  } = currentControllers || {};

  return (controllersData || []).find(({ id: itemId }) => id === itemId);
}

function MakeVendotekControllerModalContent({
  id,
  facilityId,
  onClose = () => {},

  allControllersState,

  testNewControllerApi = testNewController,
  setControllerApi = setController,
}) {
  const styles = useStyles();
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [isTestLoading, setIsTestLoading] = useState(false);
  const [isSetLoading, setIsSetLoading] = useState(false);
  const [snValue, setSnValue] = useState('');

  const { data: vpnClients } = useOpenVpnClients({ variables: { facilityId } });
  const vpnItems = useMemo(() => (vpnClients || []).map(({ id, ip }) => ({ id, title: ip })), [vpnClients]);

  const [fields, errors, onChange, setErrors, updateState] = useFormFields({
    initValues: {
      name: 'Vendotek',
      host: '',
      port: '80',
      vpn: vpnItems.length === 1 ? vpnItems[0].id : '',
      isVpn: true,
    },
  });

  useEffect(() => {
    if (!id || !facilityId) {
      return;
    }

    const controller = getCurrentController({
      id,
      facilityId,
      allControllersState,
    });

    if (controller) {
      updateState({
        name: controller.name || 'ZKTeco',
        host: controller.settings.host || '',
        port: controller.settings.port || '',
        vpn: controller.settings.vpn || '',
        isVpn: !!fields.vpn,
      });
    }
  }, [id, facilityId, updateState, allControllersState, fields.vpn]);

  const handleNextStep = useCallback(async () => {
    const { isVpn } = fields;
    const isHostValid = isVpn ? true : fields.host.length > 0;
    const isHttpPortValid = fields.port.length > 0;

    if (!isHostValid || !isHttpPortValid) {
      setErrors({
        host: !isHostValid,
        port: !isHttpPortValid,
      });
      return;
    }

    setIsTestLoading(true);

    testNewControllerApi({
      facilityId,
      type: CONTROLLER_TYPES.vendotek,
      settings: {
        host: !fields.isVpn ? fields.host : undefined,
        port: fields.port,
        vpn: fields.isVpn ? fields.vpn : undefined,
      },
    }).then(({ data, success, message }) => {
      if (!success) {
        setErrors({
          host: true,
          port: true,
        });
        setIsTestLoading(false);

        if (message) {
          toast.error(message);
        }
        return;
      }

      const {
        sn,
      } = data || {};

      setSnValue(sn);
      setIsTestLoading(false);
      setStep(1);
    }).catch((error) => {
      setIsTestLoading(false);
    });
  }, [facilityId, fields, setErrors, testNewControllerApi]);

  const handleSetController = useCallback(() => {
    setIsSetLoading(true);
    setControllerApi({
      id,
      facilityId,
      type: CONTROLLER_TYPES.vendotek,
      name: fields.name ? fields.name : 'Vendotek',
      settings: {
        host: !fields.isVpn ? fields.host : undefined,
        port: fields.port,
        vpn: fields.isVpn ? fields.vpn : undefined,
        sn: snValue || undefined,
      },
    }).then(({ success, data }) => {
      setIsSetLoading(false);
      if (success) {
        onClose();
      }
    }).catch(() => {
      setIsSetLoading(false);
    });
  }, [setControllerApi, id, facilityId, fields, snValue, onClose]);

  return (
    <CustomModalContentWrapper
      title="Подключение Vendotek"
      onClose={onClose}
      paperClassname={[step === 2 ? styles.zktecoSettings : ''].join(' ')}
    >
      {step === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              Введите данные
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.name}
              name="name"
              label="Имя контроллера"
              value={fields.name}
              onChange={onChange}
            />
          </Grid>
          {fields.isVpn ? (
            <Grid item xs={9}>
              <ItemSelectors
                classNameForm={styles.selectorsForm}
                classNameLabel={styles.selectorsLabel}
                error={errors.vpn}
                name="vpn"
                label="Хост"
                items={vpnItems}
                value={fields.vpn}
                onChange={onChange}
              />
            </Grid>
          ) : (
            <Grid item xs={9}>
              <CustomTextInput
                className={styles.inputItem}
                error={errors.host}
                name="host"
                label="Хост"
                value={fields.host}
                onChange={onChange}
              />
            </Grid>
          )}
          <Grid item xs={3}>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={fields.isVpn}
                  name="isVpn"
                  onChange={onChange}
                  color="primary"
                />
              )}
              label="VPN"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomTextInput
              className={styles.inputItem}
              error={errors.port}
              name="port"
              label="Порт"
              value={fields.port}
              onChange={onChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleNextStep}
                  disabled={isTestLoading}
                >
                  Проверить
                  {isTestLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 1 && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="span" className={styles.subTitle}>
              Cерийный номер:
              {' '}
            </Typography>
            <Typography component="span">
              {snValue}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="secondary"
                  className={styles.btn}
                  onClick={() => setStep(0)}
                >
                  Назад
                </CustomBtn>
              </Grid>
              <Grid item xs={6}>
                <CustomBtn
                  btnType="primaryBlue"
                  className={styles.btn}
                  onClick={handleSetController}
                  disabled={isSetLoading}
                >
                  {id ? 'Сохранить' : 'Добавить'}
                  {isSetLoading && (
                    <CircularProgress
                      style={{ marginLeft: 5 }}
                      size={20}
                      color="inherit"
                    />
                  )}
                </CustomBtn>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CustomModalContentWrapper>
  );
}

function mapStateToProps(state) {
  const { controllers } = state || {};
  const {
    setController: setControllerState,
    allControllers,
  } = controllers || {};

  return {
    setControllerState,
    allControllersState: allControllers,
  };
}

const ConnectedVendotekControllerModalContent = connect(
  mapStateToProps,
  {
    setControllerReg: setControllerAction,
  },
)(MakeVendotekControllerModalContent);

export default ConnectedVendotekControllerModalContent;
