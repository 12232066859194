import React, { useEffect, useMemo, useState } from 'react';
import Chart from 'react-apexcharts';
import { CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { useAnalytic } from 'api/query/analytics';
import { useDebounce } from 'helpers/hooks';

import { useStyles } from './styles';

export default function AnalyticHeatMap({
  mode,
  type,
  facilityIds,
  start,
  end,
  tariffPlanIds,
  gateIds,
  accessStatuses,
  sessionDurationFrom,
  sessionDurationTo,
}) {
  const styles = useStyles();
  const [title, setTitle] = useState('');
  const [series, setSeries] = useState([]);
  const [max, setMax] = useState([]);
  const [actualMode, setActualMode] = useState('');

  const params = useMemo(() => ({
    mode,
    type,
    facilityIds,
    tariffPlanIds,
    gateIds,
    accessStatuses,
    sessionDurationFrom,
    sessionDurationTo,
    start: start && end ? start : undefined,
    end: start && end ? end : undefined,
  }), [mode, type, facilityIds, tariffPlanIds, gateIds, accessStatuses, sessionDurationFrom, sessionDurationTo, start, end]);

  const variables = useDebounce(params, 0);

  const { data: chartData, isFetching, isLoading } = useAnalytic({
    variables,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (chartData) {
      const {
        title: chartTitle, data, mode: aMode, max: resMax,
      } = chartData;
      setTitle(chartTitle);
      setActualMode(aMode);

      setSeries(data);
      setMax(resMax);
    }
  }, [chartData]);

  const ranges = useMemo(() => {
    const step = Math.floor(max / 5);

    return [
      {
        from: -1,
        to: -1,
        color: '#CCCCCC',
        name: 'Нет данных',
      },
      {
        from: 0,
        to: step,
        color: '#69B34C',
      },
      {
        from: step,
        to: step * 2,
        color: '#ACB334',
      },
      {
        from: step * 2,
        to: step * 3,
        color: '#FAB733',
      },
      {
        from: step * 3,
        to: step * 4,
        color: '#FF8E15',
      },
      {
        from: step * 4,
        to: max,
        color: '#FF0D0D',
      },
    ];
  }, [max]);
  const options = useMemo(() => ({
    colors: ['#CCCCCC', '#69B34C', '#ACB334', '#FAB733', '#FF8E15', '#FF0D0D'],
    noData: {
      text: 'Пока пусто',
    },
    dataLabels: {
      enabled: false,
    },
    chart: {
      id: `${type}_${actualMode}`,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      fontFamily: 'Lato,Roboto',
      animations: {
        enabled: true,
        animateGradually: {
          enabled: false,
        },
      },
    },
    plotOptions: {
      heatmap: {
        enableShades: true,
        shadeIntensity: 0.5,
        radius: 0,
        colorScale: {
          ranges,
          inverse: true,
        },
      },
    },
    legend: {
      position: 'bottom',
    },
    xaxis: {
      tooltip: {
        enabled: false,
      },
    },
    tooltip: {
      followCursor: true,
      marker: {
        show: true,
      },
    },

  }), [type, actualMode, ranges]);

  if (isFetching) {
    return (
      <Paper className={styles.paper}>
        <Typography className={styles.title}>
          {title}
        </Typography>
        <Grid container style={{ height: 315 }} justifyContent="center" alignItems="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </Paper>
    );
  }

  return (
    <Paper className={styles.paper}>
      <Typography className={styles.title}>
        {title}
      </Typography>
      <Chart
        height={300}
        width="100%"
        type="heatmap"
        options={options}
        series={series}
      />
    </Paper>
  );
}
