import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import P360LogoIcon from '@parkly/shared/components/atoms/icons/P360LogoIcon';

import SettingsNavigationLinks from 'components/organisms/SettingsNavigationLinks';

import { useStyles } from './styles';

function SettingsContainer({
  children, registrationStatus, facilityId,
}) {
  const styles = useStyles();
  const theme = useTheme();

  const {
    isRegistrationCompleted,
  } = registrationStatus || {};

  return (
    <div className={styles.root}>
      <Hidden smDown implementation="css">
        <div className={styles.container}>
          {!isRegistrationCompleted && <P360LogoIcon colorP={theme.palette.primary.main} className={styles.icon} />}
          {isRegistrationCompleted && <Typography className={styles.headerStr}>Информация о парковке</Typography>}
          <SettingsNavigationLinks facilityId={facilityId} registrationStatus={registrationStatus} />
        </div>
      </Hidden>
      <div className={styles.children}>{children}</div>
    </div>
  );
}

export default SettingsContainer;
