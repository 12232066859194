import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment-timezone';

import CircularIndeterminate from '@parkly/shared/components/atoms/CircularIndeterminate';
import CustomBtn from '@parkly/shared/components/atoms/CustomBtn';
import CustomLink from '@parkly/shared/components/atoms/CustomLink';
import CircleIcon from '@parkly/shared/components/atoms/icons/CircleIcon';
import GridTitleBase from '@parkly/shared/components/molecules/GridTitleBase';
import HeaderWithBackBtn from '@parkly/shared/components/molecules/HeaderWithBackBtn';
import { formatPhoneNumber, getApiErrorMsg } from '@parkly/shared/helpers';
import useConfirm from '@parkly/shared/helpers/confirm';

import { useActivateTicketMutation, useCancelTicketMutation, useTicketInfo } from 'api/query/tickets';
import SessionsContent from 'components/organisms/Sessions/SessionsContent';
import {
  PASS_REQ_TYPES,
  PASSES_STATUSES,
  PASSES_STATUSES_COLOR_MAP,
  PASSES_STATUSES_MAP,
  PATH_PAGES,
  RIGHT_KEYS,
} from 'config/constants';
import { useCheckFacilityRights, useCommonRights } from 'helpers/hooks';
import { useCurrentFacilityMatch } from 'helpers/routerHooks';

import { useStyles } from './styles';

export const getCreatedByContent = (
  createdBy,
  facilityId,
  history,
  hasCustomerShowRight,
  hasOperatorShowRight,
) => {
  const {
    id,
    type,
    name,
    via,
  } = createdBy || {};

  if (!createdBy) {
    return (
      <Typography>-</Typography>
    );
  }

  let link = '';
  switch (type) {
    case 'operator':
      if (hasOperatorShowRight) {
        link = PATH_PAGES.oneOperator.replace(':id', id);
      }
      break;
    case 'customer':
      if (hasCustomerShowRight) {
        link = PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', id);
      }
      break;
  }

  return (
    <>
      {link ? (
        <>
          <CustomLink
            href={link}
            onClick={(e) => {
              e.preventDefault();
              history.push(link);
            }}
          >
            {name}
          </CustomLink>
          {via && (
            <>
              {' '}
              (
              {getCreatedByContent(via, facilityId, history, hasCustomerShowRight, hasOperatorShowRight)}
              )
            </>
          )}
        </>
      ) : (
        <Typography>
          {name}
        </Typography>
      )}
    </>
  );
};

function OneTicketInfoPage({
  match,
}) {
  const styles = useStyles();
  const history = useHistory();
  const queryClient = useQueryClient();
  useCurrentFacilityMatch(PATH_PAGES.tickets);
  const confirm = useConfirm();

  const { params, path } = match || {};
  const {
    id: ticketId,
    facilityId,
  } = params || {};

  const hasTicketUpdateRight = useCheckFacilityRights(RIGHT_KEYS.ticketUpdate, facilityId);
  const hasTicketTypeShowRight = useCheckFacilityRights(RIGHT_KEYS.ticketTypeShow, facilityId);
  const hasCustomerShowRight = useCheckFacilityRights(RIGHT_KEYS.customerShow, facilityId);
  const hasSessionShowRight = useCheckFacilityRights(RIGHT_KEYS.sessionShow, facilityId);
  const hasOperatorShowRight = useCommonRights(RIGHT_KEYS.admin);

  const {
    data: currentTicket,
    isLoading,
    error: currentTicketError,
    refetch: ticketRefetch,
  } = useTicketInfo({
    variables: { facilityId, id: ticketId },
  });

  const activateTicketMutation = useActivateTicketMutation({
    onSuccess({ success }) {
      if (success) {
        ticketRefetch();
      }
    },
  });

  const { isLoading: isActivateLoading } = activateTicketMutation || {};

  const handleActivateTicketClick = useCallback(() => {
    confirm({
      title: 'Вы уверены, что хотите активировать пропуск?',
      agreeTitle: 'Активировать',
      disagreeTitle: 'Отменить',
    }).then(() => {
      activateTicketMutation.mutate({ facilityId, id: ticketId });
    }).catch(() => {});
  }, [activateTicketMutation, confirm, facilityId, ticketId]);

  const cancelTicketMutation = useCancelTicketMutation({
    onSuccess({ success }) {
      if (success) {
        ticketRefetch();
      }
    },
  });

  const { isLoading: isCancelLoading } = cancelTicketMutation || {};

  const handleCancelTicketClick = useCallback(() => {
    confirm({
      title: 'Вы уверены, что хотите отменить пропуск?',
      agreeTitle: 'Да, отменить',
      disagreeTitle: 'Нет',
    }).then(() => {
      cancelTicketMutation.mutate({ facilityId, id: ticketId });
    }).catch(() => {});
  }, [cancelTicketMutation, confirm, facilityId, ticketId]);

  const {
    number,
    status,
    comment,
    customer,
    ticketType,
    place,
    startTime,
    finishTime,
    url,
    requiredData,
    sessions,
    canBeCanceled,
    createdAt,
    createdBy,
  } = currentTicket || {};

  const {
    id: customerId,
    name: customerName,
  } = customer || {};

  const {
    name: placeName,
    areaName,
  } = place || {};

  const {
    id: passTypeId,
    name: passTypeName,
  } = ticketType || {};

  const reqData = useMemo(() => {
    const result = {};
    (requiredData || []).forEach(({ type, value }) => {
      if (type.includes('additional')) {
        return;
      }

      result[type] = value;
    });

    return result;
  }, [requiredData]);

  const additionalPhones = useMemo(() => (requiredData || []).filter(({ type }) => type === PASS_REQ_TYPES.additionalPhone), [requiredData]);

  const additionalPlateNumbers = useMemo(() => (requiredData || []).filter(({ type }) => type === PASS_REQ_TYPES.additionalPlateNumber), [requiredData]);

  const {
    phone,
    fio,
    carModel,
    plateNumber,
    stsNumber,
    idNumber,
    rfid,
    pinCode,
    barcode,
  } = reqData || {};

  if (isLoading) {
    return (
      <CircularIndeterminate style={{ minHeight: 600 }} />
    );
  }

  if (currentTicketError) {
    const currentTicketErrorMsg = getApiErrorMsg(currentTicketError);
    return (
      <Container className={styles.container}>
        <HeaderWithBackBtn
          title="Пропуск"
        />
        <Typography>{currentTicketErrorMsg}</Typography>
      </Container>
    );
  }

  return (
    <Container className={styles.container} maxWidth="md">
      <HeaderWithBackBtn
        title={`Пропуск #${number ? number.substring(9) : number}`}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid className={styles.gridContainer} container item spacing={3} xs={12} md={7}>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Создан"
              titleSm={5}
            >
              <Typography>
                {moment(createdAt).format('DD.MM.YYYY HH:mm')}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Заказчик"
              titleSm={5}
            >
              <Typography>
                {getCreatedByContent(createdBy, facilityId, history, hasCustomerShowRight, hasOperatorShowRight)}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Тип пропуска"
              titleSm={5}
            >
              {hasTicketTypeShowRight ? (
                <CustomLink
                  href={PATH_PAGES.oneTicketSettings.replace(':facilityId', facilityId).replace(':ticketId', passTypeId)}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(PATH_PAGES.oneTicketSettings.replace(':facilityId', facilityId).replace(':ticketId', passTypeId));
                  }}
                >
                  {passTypeName}
                </CustomLink>
              ) : (
                <Typography>
                  {passTypeName}
                </Typography>
              )}
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Статус"
              titleSm={5}
            >
              <Typography className={styles.statusStr}>
                <CircleIcon color={PASSES_STATUSES_COLOR_MAP[status] || 'grey'} />
                {PASSES_STATUSES_MAP[status] || '-'}
              </Typography>
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title="Гость"
              titleSm={5}
            >
              {hasCustomerShowRight ? (
                <CustomLink
                  href={PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId)}
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(PATH_PAGES.oneCustomerInfo.replace(':facilityId', facilityId).replace(':id', customerId));
                  }}
                >
                  {customerName}
                </CustomLink>
              ) : (
                <Typography>
                  {customerName}
                </Typography>
              )}
            </GridTitleBase>
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title={(
                <div className={styles.colFlex}>
                  <Typography className={styles.bold}>Начало</Typography>
                  <Typography>{moment(startTime).format('DD.MM.YYYY HH:mm')}</Typography>
                </div>
              )}
              titleSm={5}
            >
              <div className={styles.colFlex}>
                <Typography className={styles.bold}>Окончание</Typography>
                <Typography>{moment(finishTime).format('DD.MM.YYYY HH:mm')}</Typography>
              </div>
            </GridTitleBase>
            {placeName && (
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Машиноместо"
                titleSm={5}
              >
                <Typography>
                  {placeName}
                </Typography>
              </GridTitleBase>
            )}
            <GridTitleBase
              classNameContainer={styles.itemContainer}
              title={(
                <div className={styles.colFlex}>
                  {phone && <Typography>Номер телефона</Typography>}
                  {(additionalPhones || []).map(({}, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={`add-phone-title-${index}`}>Доп. номер телефона</Typography>
                  ))}
                  {fio && <Typography>ФИО</Typography>}
                  {plateNumber && <Typography>Госномер авто</Typography>}
                  {(additionalPlateNumbers || []).map(({}, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Typography key={`add-plate-title-${index}`}>Доп. госномер авто</Typography>
                  ))}
                  {carModel && <Typography>Марка и модель авто</Typography>}
                  {stsNumber && <Typography>Номер СТС</Typography>}
                  {idNumber && <Typography>Номер паспорта</Typography>}
                  {rfid && <Typography>ID (Карта)</Typography>}
                  {pinCode && <Typography>Пин-код</Typography>}
                  {barcode && <Typography>Штрихкод</Typography>}
                </div>
              )}
              titleSm={5}
            >
              <div className={styles.colFlex}>
                {phone && <Typography>{formatPhoneNumber(phone)}</Typography>}
                {(additionalPhones || []).map(({ value }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography key={`add-phone-value-${index}`}>{formatPhoneNumber(value)}</Typography>
                ))}
                {fio && <Typography>{fio}</Typography>}
                {plateNumber && <Typography>{plateNumber}</Typography>}
                {(additionalPlateNumbers || []).map(({ value }, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Typography key={`add-plate-value-${index}`}>{value}</Typography>
                ))}
                {carModel && <Typography>{carModel}</Typography>}
                {stsNumber && <Typography>{stsNumber}</Typography>}
                {idNumber && <Typography>{idNumber}</Typography>}
                {rfid && <Typography>{rfid}</Typography>}
                {pinCode && <Typography>{pinCode}</Typography>}
                {barcode && <Typography>{barcode}</Typography>}
              </div>
            </GridTitleBase>
            {comment && (
              <GridTitleBase
                classNameContainer={styles.itemContainer}
                title="Комментарий"
                titleSm={5}
              >
                <Typography>
                  {comment}
                </Typography>
              </GridTitleBase>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} item xs={12}>
          {hasTicketUpdateRight && [PASSES_STATUSES.active, PASSES_STATUSES.draft].includes(status) && (
            <Grid item>
              <CustomBtn
                className={styles.btn}
                onClick={() => {
                  history.push(PATH_PAGES.oneTicket.replace(':facilityId', facilityId).replace(':id', ticketId));
                }}
              >
                <EditIcon style={{ marginRight: 5 }} fontSize="small" />
                Редактировать
              </CustomBtn>
            </Grid>
          )}
          {hasTicketUpdateRight && status === PASSES_STATUSES.draft && (
            <Grid item>
              <CustomBtn
                btnType="primaryGreen"
                className={styles.btn}
                disabled={isActivateLoading || isCancelLoading}
                onClick={handleActivateTicketClick}
              >
                <PlayArrowIcon style={{ marginRight: 5 }} fontSize="small" />
                Активировать
                {isActivateLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
          )}
          {hasTicketUpdateRight && canBeCanceled && (
            <Grid item>
              <CustomBtn
                className={styles.btn}
                btnType="primaryRed"
                onClick={handleCancelTicketClick}
                disabled={isCancelLoading || isActivateLoading}
              >
                <CancelIcon style={{ marginRight: 5 }} fontSize="small" />
                Отменить
                {isCancelLoading && (
                  <CircularProgress
                    style={{ marginLeft: 5 }}
                    size={20}
                    color="inherit"
                  />
                )}
              </CustomBtn>
            </Grid>
          )}
        </Grid>
        {hasSessionShowRight && (
          <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
              <Typography className={styles.subTitle}>
                Последние сесcии
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <SessionsContent
                facilityId={facilityId}
                sessionsData={sessions || []}
                hideCheckbox
                hideCustomer
                onNeedUpdate={() => {
                  queryClient.invalidateQueries({ queryKey: ['customers'] });
                  queryClient.invalidateQueries({ queryKey: ['sessions'] });
                  queryClient.invalidateQueries({ queryKey: ['payments'] });
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Container>
  );
}

export default OneTicketInfoPage;
